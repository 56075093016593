@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Rubik&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td,
th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  font-size: 18px;
  text-align: left;
  padding: 0 5px;
}

th {
  font-size: 18px;
  text-align: left;
  padding: 5px;
  padding-bottom: 15px;
  font-weight: bold;
}
/* Add Globle Css */
.MuiTypography-subtitle1 {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  background-color: rgb(247 247 247);
}
.MuiTypography-subtitle1 .MuiCheckbox-colorPrimary {
  padding: 5px;
}
.MuiTypography-subtitle1 label {
  font-weight: 400;
}
.buttonCheck button {
  width: 100%;
}
.buttonCheck {
  flex-grow: 1;
}
.MuiTypography-subtitle1 .MuiAccordionSummary-root {
  width: 100%;
  background-color: transparent;
}
.fileUplodeStyle {
  margin: 0px;
  padding-left: 10px;
  font-weight: 400;
  max-width: 224px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cloneIcon {
  display: inline-block;
  line-height: 1;
  padding: 2px 10px 7px 10px;
  background-color: #1976d2;
  color: #ffffff;
  font-size: 30px;
  align-items: center;
  cursor: pointer;
}

.cloneTbl {
  padding: 13px 3px 0px;
}
.fileUplodeTab {
  padding: 20px 0px;
}
.hideother .makeStyles-accordionContainer-19 {
  display: none;
}
.hideother .makeStyles-tableNavbarStyle-24 {
  display: none;
}

.hideother .makeStyles-align-18 {
  background-color: #ffffff;
}
.hideother > .jss24 {
  display: none;
}

.hideother .jss17 > .jss19 {
  display: none;
}
.hideother > div:first-child {
  display: none;
}

.hideother > div + div > div:first-child {
  display: none;
}

.hideother > div + div > div + div {
  background-color: #ffffff;
  overflow-y: auto;
  max-height: calc(100vh - 172px);
}

.hideother {
  border: none !important;
}
.css-79ws1d-MuiModal-root {
  overflow-y: auto;
}
.EssentialIndicatorsColumns .css-18vnt51-MuiTypography-root:first-child {
  display: none;
}
.kPbKIB {
  scale: 2;
}
.hoverdroup {
  position: absolute;
      right: 10px;
      top: 0px;
      height: 33px;
      width: 25px;
  }
.dropdown_history {
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #ececec;
  position: absolute;
  z-index: 2;
  background: #ffffff;
  width: 150px;
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.2s;
  transition-property: opacity, visibility;
  transition: all 0.2s;
  top: 33px;
  right: 0px;
  margin: 0;
  left: auto;
  border-radius: 5px;
  padding: 0px;
  overflow: hidden;
  max-height: 300px;
  overflow-y: auto;
}
.dropdown_history.active_log {
  transition: all 0.2s;
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
  transition-property: opacity, visibility;
}
.dropdown_history li {
  padding: 2px 10px;
}
.dropdown_history li:not(:last-child) {
  border-bottom: 1px solid #ececec;
}
.headerli:first-child {
  background-color: #dddddd;
  font-size: 13px;
  padding: 5px 10px;
}

.dropdown_history .datestemp {
  color: #3c7b00;
  white-space: nowrap;
  font-size: 11px;
  display: block;
}

time.datesstt {
  color: #3c7b00;
  white-space: nowrap;
  font-size: 12px;
}
.tabletime {
  font-size: 15px;
}

.successalert {
  padding: 20px 14px;
  color: #3c7b00;
  font-size: 15px;
  text-align: center;
}
.successalertfailed {
  padding: 20px 14px;
  color: #b30000;
  font-size: 15px;
  text-align: center;
}
.moreHorizTwoToneIconStyle {
  min-width: auto !important;
  margin: 0px !important;
}

.pencil, .clock {
  line-height: 1;
  margin: -3px -6px;
}

.pencil .MuiButtonBase-root, .clock .MuiButtonBase-root {
  padding: 4px;
  line-height: 0;
}

.primary-custom {
  background-color: #3AD865 !important;
  color: #ffffff !important;
  border: 1px solid #3AD865 !important;
  box-shadow: 0px 3px 5px #00000033 !important;
  font-size: 1rem !important;
  padding: 5px 15px !important;
  margin-left: 20px !important;
}

.rejectbtn {
  font-size: 15px !important;
  line-height: 1.3;
  display: inline-block;
  background-color: #dddddd;
  border-radius: 2px;
  padding: 3px 9px;
}
