.Mui-selected {
  color: White !important;
  font: normal normal 600 16px/19px Montserrat !important;
  font-size: 20px;
}
.ePlOqe .th {
  background-color: #e46d35 !important;
  // background-color: #2A5BA8 !important;
  color: white;
  height: 64;
}
.ePlOqe .headerContent {
  padding: 20px 8px !important;
}

.loaderStyle {
  position: absolute !important;
  top: 50vh;
  left: 50%;
}

.buttonModal {
  margin: 0;
  padding: 16px;
  box-shadow: -2px 0px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ePlOqe .columnHiding {
  font-size: 27px;
}
.ePlOqe [data-sticky-last-left-td] {
  box-shadow: 6px 0 10px -1px rgb(0 0 0 / 8%) !important;
}

.td {
  box-shadow: 0.05px 0px 0px 0.05px !important;
}
.moreHorizTwoToneIconStyle:hover {
  background: #f1f4f6 0% 0% no-repeat padding-box !important;
  border-radius: 50%;
}
.editTableStyle {
  padding: 10px 40px !important;
  cursor: pointer;
  margin: 10px 0 !important;
}
.fileInputHolder {
  width: 323px !important;
  display: flex;
  align-items: center;
  border: 1px dashed lightgray;
  padding: 10px;
  height: 35px !important;
  background-color: #e5e5e5;
}
input[type="file"] {
  display: none !important;
}

input:-internal-autofill-selected {
  background-color: white !important;
}
.userImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

// ::-webkit-scrollbar {
//   width: 0; /* Remove scrollbar space */
//   background: transparent; /* Optional: just make scrollbar invisible */
// }

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.downloadStyle {
  padding: "20px !important";
  height: "200px !important";
}
